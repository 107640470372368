.container {
  background: #292930;
  display: grid;
}

.inner {
  margin: 6.25rem auto;
  display: flex;
  color: #FFFFFF;
  max-width: 75rem;
  justify-content: space-between;
}

.inner button {
  font-family: var(--bigfont);
  font-size: 18px;
  font-weight: 500;
  margin-left: 8.37rem;
  padding: 1rem 1.25rem;
  border-radius: 7px;
}

.logo img {
  width: 88px;
  height: 88px;
  margin-right: 2.438rem;
}


.heading h2 {
  font-family: var(--bigfont);
  font-size: 40px;
  font-weight: 600;
  max-width: 44.87rem;
  margin-top: 1rem;
}

.content {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  width: 30%;
}

.sidebar-item {
  background-color: #2e2e2e;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-item.active {
  background-color: #3e3e3e;
}

.sidebar-title {
  color: #ff6347;
  font-weight: bold;
}

.sidebar-subtitle {
  font-size: 1.2em;
  margin-top: 5px;
}

.main-content {
  width: 65%;
}

.main-content img {
  width: 100%;
  border-radius: 10px;
}

.inner2 {
  display: flex;
  justify-content: center;
  gap: 1.75rem
}

.accordian {
  flex-basis: 50%;
  max-width: 34.87rem;
}

.sideimage {
  /* background: linear-gradient(316.69deg, #FFDA57 -11.19%, #FFDA57 20.41%, #5956E8 71.73%, #5956E8 110.15%); */
  max-width: 38.37rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.93rem 0;
  flex-basis: 50%;

}

.sideimage img {
  max-width: 28.46rem;
  max-height: 28.46rem;
  border-radius: 10px;
}

.btn2 {
  display: none;
}

@media screen and (max-width: 1024px) {
  .heading span {
    display: none;
  }

  .logo img {
    margin-right: auto;
  }

  .inner {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 3.12rem auto;
  }

  .inner button {
    display: none;
  }

  .sideimage {
    display: none;
  }

  .inner2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }

  .btn2 {
    display: flex;
    font-family: var(--bigfont);
    font-size: 18px;
    font-weight: 500;
    max-width: 15.8rem;
    padding: 1rem 1.25rem;
    border-radius: 7px;
    align-items: center;
    justify-content: space-around;
  }

  .heading h2 {
    font-size: 30px;
  }

  .accordian {
    flex-basis: 90%;
  }
  
  
}