.conatiner {
    display: flex;
    flex-direction: column;
    max-width: 74.942rem;
}

.container2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog_first {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem auto;
}

.content h2 {
    font-family: var(--bigfont);
    font-size: 40px;
    font-weight: 600;
    color: #292930;
}

.blog_first img {
    margin: 0 2.438rem;
    width: 88px;
    height: 88px;
}


.card1, .card2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff4c2;
    padding: 85px 90px 85px 90px;
    border-radius: 10px;
    max-width: 74.94rem;
    margin: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.62rem;
    align-items: center;
    padding-right: 2.18rem;
}

.category {
    background-color: #fff4c2;
    padding: 5px 10px;
    border-radius: 25px;
    border: 1px solid #292930;
    font-family: (--bigfont);
    font-size: 18px;
    font-weight: 500;
}

.date {
    color: #292930;
    font-family: Noto Sans;
    font-size: 15px;
    font-weight: 500;
}

.title {
    font-weight: 600;
    margin-bottom: 10px;
    font-family: Nunito;
    font-size: 25px;
}

.description {
    color: #292930;
    margin-bottom: 20px;
    font-family: Noto Sans;
    font-size: 18px;
    font-weight: 400;

}

.button {
    border: none;
    cursor: pointer;
    background: #FFFFFF;
    width: 158px;
    height: 64px;
    padding: 17px 24px 18px 24px;
    gap: 10px;
    border-radius: 7px;
    font-family: var(--bigfont);
    font-size: 18px;
    font-weight: 500;
}

.imageContainer {
    flex-shrink: 0;
    margin-left: 20px;
}

.image {
    border-radius: 10px;
    width: 453.07px;
    height: 296.93px;
}

.card2 {
    background-color: #E9F7FF;
}

.category2 {
    background-color: #E9F7FF;
    padding: 5px 10px;
    border-radius: 25px;
    border: 1px solid #292930;
    font-family: var(--bigfont);
    font-size: 18px;
    font-weight: 500;
}

.content2 {
    flex: 1;
    padding-left: 20px;
    max-width: 34.875rem;
}

.image2 {
    border-radius: 10px;
    gap: 0px;
    width: 453.07px;
    height: 296.93px;
}

.button2 {
    align-items: center;
    padding: 17px 35px 18px 35px;
    font-size: 18px;
    color: #FFFFFF;
    background-color: #292930;
    border: none;
    border-radius: 7px;
    text-align: center;
    gap: 10px;
    font-family: var(--bigfont);
    font-weight: 500;
}
.visbleDate1{
    display: none;
}
.visiblebutton2{
    display: none;
}

@media screen and (max-width: 1024px) {

    .visbleDate{
        display: none;
    }
    .visiblebutton1{
        display: none;
    }
    .visbleDate1{
        display: flex;
        margin-bottom: 1.62rem;
        
    }
    .visiblebutton2{
        display: flex;
        margin-bottom: 1.62rem;
    }
    
    .blog_first img {
        margin: 1rem 0;
        width: 54px;
        height: 54px;
    }

    .content h2{
        font-size: 30px;
        margin: auto;
        text-align: center;
    }
    .content span {
        display: none;
    }

    .blog_first {
        flex-direction: column;
        margin: 4rem auto;
    }

    .imageContainer img {
        max-width: 9.306rem;
        max-height: 6.438rem;
    }

    .card1, .card2 {
        padding: 24px 20px;
    }

    .title {
        font-size: 15px;
    }

    .category2 {
        font-size: 10px;
    }

    .description {
        font-size: 10px;
    }

    .category, .category2 {
        font-size: 15px;
    }

    .date {
        font-size: 12px;
    }

    .button{
        font-size: 15px;
        padding: 5.5px 14px;
        width: auto;
        height: auto;
    }

    .header {
        padding: 0rem;
    }



}