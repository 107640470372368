.card {
    background: linear-gradient(151.16deg, #FFF8F8 10.38%, #F5F7FF 95.44%);
    display: flex;
    flex-direction: column;
    max-width: 36.62rem;
    padding: 2rem 1.62rem;
    gap: 2.31rem;
}

.innerCard {
    box-shadow: 0px 2px 4px 0px #92929240;
    background: #FFFFFF;
    padding: 3rem 2.31rem;
    border-radius: 7px;
}

.about {
    display: flex;
    align-items: center;
    gap: 8px;
}

.name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;

}

.name span {
    font-family: var(--smallfont);
    font-size: 25px;
    font-weight: 700;
}

.introduce {
    display: flex;
    flex-direction: column;
}

.about img {
    margin: 2px;
}

.client {
    font-family: var(--bigfont);
    font-size: 20px;
    font-weight: 700;
    color: #292930;
}

.designation {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    color: #555555;
}

.heading p {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    color: #555555;
}

@media screen and (max-width: 1024px) {

    .heading img{
        width: 96px;
    }

    .name span{
        font-size: 18px;
    }
    .heading p{
        font-size: 12px;
    }
    .card {
        gap: 0.9rem;
    }
}