.back {
    background: url("../../assets/BannerBackground.png");
    background-position: center;
    min-height: 100vh;
}

.landing {
    display: flex;
    align-items: center;
    justify-content: center;

}

.landing h2 {
    font-size: 70px;
    font-family: var(--bigfont);
    font-weight: 800;
}

.landing p {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    max-width: 37.063rem;
}

.landing span {
    font-size: 15px;
    font-family: var(--smallfont);
}

.imgs {
    display: flex;
}

.inner img {
    display: none;
}

.imgs img {
    margin-left: -15rem;
    margin-top: -3rem;
    width: 115%;
    height: 100%;
}

.buts {
    display: flex;
    gap: 1.25rem;
}

.buts button {
    font-family: var(--bigfont);
    font-size: 18px;
    font-weight: 500;
    /* width: 182px;
    height: 64px; */
    padding: 17px 39px 18px 39px;
    border-radius: 7px;
}

.inner {
    flex-basis: 90%;
    max-width: 46.25rem;
    position: relative;
    margin-left: 16rem;
}


@media screen and (max-width: 1024px) {
    .landing {
        flex-direction: column;
        text-align: center;
    }

    .inner {
        margin: auto;
        padding: 0 1rem;
    }

    .inner span {
        display: none;
    }

    .landing h2 {
        font-size: 35px;
    }

    .landing p {
        font-size: 15px;
        margin-bottom: 0;
    }

    .inner img {
        display: flex;
        width: 100%;
    }

    .imgs {
        display: none;
    }

    .buts {
        display: flex;
        gap: 1.5rem;
        justify-content: center;
        margin: 3.06rem 0;
    }

    .buts button {
        font-size: 15px;
        width: auto;
        height: auto;
        padding: 7.5px 13.5px;
    }


}

