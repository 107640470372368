.back {
  background: #292930;
  padding: 10.813rem 22.5rem;
}

.inner {
  display: flex;
  color: #FFFFFF;
  align-items: center;
}

.inner button {
  font-family: var(--bigfont);
  font-size: 18px;
  font-weight: 500;
  padding: 17px 24px 18px 24px;
  border-radius: 7px;
}

.logo {
  width: 88px;
  height: 88px;
  margin-right: 2.438rem;
}


.heading h2 {
  max-width: 38.75rem;
}

.develop__container {
  display: grid;
  grid-template-columns: 1fr;
}

.develop__options {
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
}

.develop__option {

  padding: 2.43rem 2.38rem;
  border-radius: 1.2rem;
  border: 1px solid #424245;
  background: linear-gradient(180deg, #36363E 0%, #292930 100%);
  color: #FFFFFF;
  border: 1px solid #424245;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

}

.develop__option:hover {
  background: transparent;
  border-color: #424245;
}

.develop__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.develop__option h5 {
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 20px;
}

.develop__option p {
  text-align: justify;
}

.develop .line {
  content: "";
  width: 200px;
  height: 2.2px;
  border-radius: 4px;
  background: #34F8DD;
  display: inline-block;
  margin: 0 15px 10px 0;
}

.rowss {
  display: flex;
  gap: 2.18rem;
  flex-direction: row;
  justify-content: flex-end;
}

.buttonbtn {
  padding: 17px 68px 18px 70px;
  border-radius: 7px;
  background: transparent;
  border: 1px solid #ffff;
  font-family: var(--bigfont);
  color: #ffff;
}

.buttonbtn:hover {
  background: #FFCD1F;
  color: #000;
  border: none;
}

.spanicon {
  padding: 15px 15px 15px 15px;
  border-radius: 60%;
  display: flex;
  color: white;
  width: 60px;
  background: #ffffff;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 1rem; */
  width: 153px;
  height: 153px;
  gap: 0px;
  opacity: 0px;

}

.spanicon img {
  width: 75.63px;
  height: 84.63px;
}


@media screen and (max-width: 1024px) {
  .back {
    padding: 2.43rem;
  }

  .heading span {
    display: none;
  }

  .rowss {
    flex-direction: column;
  }

  .inner {
    flex-direction: column;
  }
  .heading h2 {
    text-align: center;
  }

  .logo {
    width: 54px;
    height: 54px;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .inner button {
    font-size: 15px;
    padding: 8px 24px;
    margin: 3rem;
  }
  .buttonbtn {
    padding: 15px 50px ;
  }

}