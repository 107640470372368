.container {
    margin: 18.68rem auto;
    max-width: 74.93rem;
}

.content {
    display: flex;
    gap: 1.62rem;
}

.title {
    display: flex;
    max-width: 38.25rem;
    gap: 2.43rem;
}

.heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.43rem;
}

.topics h2 {
    font-family: var(--bigfont);
    font-size: 40px;
    font-weight: 600;
}

.topics span {
    font-family: var(--smallfont);
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    color: #292930;
}

.signs {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1024px) {
     
    .container{
        margin: 1.625rem 0;
    
    }

    .topics span {
        display: none;
    }

    .title {
        flex-direction: column;
        margin-left: 1.43rem;
        align-items: center;
    }

    .content {
        flex-direction: column;
    }

    .signs img {
        width: 21px;
    }

    .topics h2 {
        font-size: 30px;
    }
    .icon img{
        width: 54px;
    }
}