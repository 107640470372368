.container {
    background-color: #292930;
    display: flex;
    padding: 11.93rem 0;
    align-items: center;
    justify-content: center;
    gap: 6.18rem;
    margin-top: 2.50rem;
}

.contact {
    display: flex;
    gap: 6.8rem;
    align-items: stretch;
    margin-bottom: 3.12rem;
}

.phone {
    display: grid;
}

.phone span:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 3px;
}

.phone span:nth-child(2) {
    background: #3C3C45;
    color: #FFDC60;
    font-family: var(--smallfont);
    font-size: 25px;
    font-weight: 700;
    padding: 0.75rem 2rem;
    border-radius: 7px;
    margin: 1rem 0;
}

.phone p {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 0;
}

.phone span:nth-child(3) {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
}

.location {
    display: grid;
    max-width: 18.56rem;

}

.location span:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 3px;
}

.location span:nth-child(2) {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;

}

.location p {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 0;
}

.location a {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    color: #FFDC60;
}

.info {
    border-top: 1px solid #727274;
    border-bottom: 1px solid #727274;
    padding: 3.12rem 0;
    display: flex;
    gap: 6.93rem;
    margin-bottom: 1rem;
}

.routes {
    display: grid;
    color: #FFFFFF;
    gap: 1rem;
}

.routes h4 {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0.87rem;
}

.routes span {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
}


.socialMedia {
    display: grid;
    justify-items: center;
}

.socialMedia h4 {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0.87rem;
    color: #FFFFFF;
}

.copyright {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    margin: 1rem 3rem;
}


@media screen and (max-width: 1024px) {
    .container {
        flex-direction: column;
        padding: 11.93rem 2rem;
    }

    .location {
        display: none;
    }

    .info {
        border: none;
        gap: 60;
        justify-content: space-between;
    }
    .phone p{
       font-size: 25px;
    }
    .title img{
        width: 30px;
        height: 30px;
    }
    .copyright{
        font-size: 15px;
    }
}