.navbar {
  --bs-navbar-color: #292930;
  --bs-navbar-hover-color: #5A41DC;
}


.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
  justify-content: center;
  gap: 30.12rem;
}

.navbar>.container-fluid {
  align-items: center;
}

.nav-link {
  font-size: 18px;
}

.blueline {
  gap: 32px;
}


.blue::after {
  content: '';
  display: inline-block;
  position: relative;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 15px;
  left: 0;
  background-color: #5A41DC;
  transform-origin: top left;
  transition: transform 0.6s ease-out;
}


.blue:hover::after {
  transform: scaleX(1);
}


.uline::after {
  content: '';
  position: absolute;
  display: inline-block;
  position: relative;
  width: 100%;
  transform: scaleX(1);
  height: 2px;
  bottom: 15px;
  left: 0;
  background-color: #5A41DC;
  transform-origin: top left;
  transition: transform;
}

.navbar-brand{
  padding: 32px 0 31.56px 39px;
}

/* --------------------responsiveness---------------- */
@media screen and (max-width: 1024px) {


  .navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    justify-content: space-between;
    gap: 1rem;
  }

  .uline::after {
    display: none;
  }

  .blue::after {
    display: none;
  }


  .blueline {
    text-align: center;
  }


  .navbar>.container-fluid {
    align-items: center;
  }

  .navbar-toggler-icon {
    background-image: url("../../assets/burger.png");
    width: 48px;
    height: 48px;
  }

  .navbar-toggler {
    border: none;
    margin-right: 1rem;
    padding: 0%;
  }

  .navbar-toggler:focus, .navbar-toggler:active, .navbar-toggler:focus:active {
    outline: none;
    box-shadow: none;
  }

  .blueline button {
    display: none;
  }

  .navbar-brand{
    padding: 2rem 1rem;
  }

}