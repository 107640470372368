.Benefits {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: #f9f9f9;
    gap: 1rem;
}

.Benefits h2.heading {
    font-family: var(--bigfont);
    font-size: 40px;
    font-weight: 600;
}

.blog_first {
    display: flex;
}

.blog_first img {
    margin-right: 2.438rem;
    margin-bottom: 2.56rem;
    width: 88px;
    height: 88px;
}

.content {
    display: flex;
    flex-direction: column;
    font-family: var(--bigfont);
    font-size: 40px;
    font-weight: 600;
    max-width: 26.31rem;
    gap: 1rem;
}

.content span {
    font-family: var(--smallfont);
    font-size: 15px;
    font-weight: 500;
}

.content h2 {
    margin: 0;
    font-family: var(--bigfont);
    font-size: 40px;
    font-weight: 600;
}

.right {
    display: flex;
    width: 34.84rem;
    height: 28.66rem;
    background: url(../../assets/bg.png);
    align-items: center;
    justify-content: center;

}

.right img {
    max-width: 26.38rem;
    height: 21.30rem;
}

.head {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
    padding: 0rem 1rem;
}

.lines {
    display: flex;
    margin: 1.43rem 0;
}

.icon {
    width: 86px;
    height: 86px;
    padding: 2rem 1.62rem;
    border-radius: 50%;
    border: 1px solid #D5D5D5;
    display: flex;
    align-items: center;
    justify-content: center;

}

.innerline p {
    padding: 1.37rem 1.2rem;
    border: 1px solid #CFCFCF;
    border-left: none;
    border-right: none;
    border-bottom: none;
    color: #555555;
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    margin-top: 0.5rem;
}

.results {
    display: flex;
    flex-direction: column;
    max-width: 35.62rem;
}


@media screen and (max-width: 1024px) {
    .Benefits {
        flex-direction: column;
        background: url(../../assets/bg.png);
        background-position: top;
    }


    .blog_first {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .blog_first img {
        margin: auto;
        margin-bottom: 1.81rem;
        width: 54px;
        height: 54px;
    }

    .blog_first span {
        display: none;
    }

    .content h2 {
        font-size: 30px;
    }

    .right img {
       width: 100%;
       height: 100%;
    }
    .right {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 1rem;
    }

    .icon {
        width: 60px;
        height: 60px;
        padding: 1.5rem;
    }

    .icon img{
        width: 23.72px;
    }

    .head{
        font-size: 18px;
        padding: 0rem 0.81rem;
    }

    .innerline p {
        font-size: 12px;
        padding: 0.37rem 1rem;
    }
}