@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
  --smallfont : "Noto Sans", sans-serif;
  --bigfont:  "Nunito", sans-serif;
}
*{
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}
body {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

}

/* Webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Thumb color (transparent makes it invisible) */
}

::-webkit-scrollbar-track {
  background-color: transparent;
  /* Track color (transparent makes it invisible) */
}