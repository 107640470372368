.container {
    /* background: linear-gradient(111.05deg, #E9F7FF 9.66%, #FFDBD5 57.52%, #FFF3CA 103.42%); */
    background: linear-gradient(111.05deg, #E9F7FF 9.66%, #D5DBFF 57.52%, #B4B3FF 103.42%);

    padding: 8.563rem 0;
}

.heading {
    max-width: 32.813rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: auto;
}

.heading span {
    font-family: var(--smallfont);
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.heading h2 {
    font-family: var(--bigfont);
    font-size: 40px;
    font-weight: 600;
    color: #292930;
    text-align: center;
}

.heading img {
    margin-bottom: 2.12rem;
}

.contains {
    display: grid;
}

.content {
    margin-top: 3.93rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.62rem;
}

.number1, .number2, .number {
    width: 86px;
    height: 86px;
    background-color: #FFFFFF;
    border: 9px solid #B4B3FF;
    border-radius: 50%;
    font-family: var(--bigfont);
    font-size: 30px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}


.number:after, .number1:after {
    content: "";
    height: 219px;
    width: 0px;
    display: inline-grid;
    position: relative;
    left: -8px;
    top: 150px;
    bottom: 0;
    width: 0;
    border-left: 1.5px dashed #E38676;
}

.number1:after {
    height: 260px;
    top: 170px;
}

.material {
    max-width: 20.75rem;
}

.material p {
    color: #555555;
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
}

.material h3 {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
    color: #292930;
}

.procImg {
    margin-left: -5.3rem;
}


@media screen and (max-width: 1024px) {
    .heading h2 {
        font-size: 30px;
        margin: 1rem 2rem 0 2rem;
    }
    .contains{
        padding: 0.5rem;
    }

    .content {
        gap: 0.5rem;
    }

    .content img {
        width: 35%;

    }

    .number1, .number {
        width: 75px;
        height: 41px;
        font-size: 18px;
    }

    .number2 {
        width: 51px;
        height: 41px;
        font-size: 18px;
    }
    /* .content p{
        max-width: 13.43rem;
    } */

    .material h3 {
        font-size: 20px;
    }

    .material p {
        font-size: 12px;
    }

    .number:after, .number1:after {
        height: 142px;
        left: -6px;
        top: 92px;
    }

    .procImg {
        margin: auto;
    }

}