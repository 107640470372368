.container {
    margin: 10rem auto;
}

.contact_first {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    display: grid;
}

.contact_first img {
    margin: 2.12rem;
}

.content span {
    font-family: var(--smallfont);
    font-size: 15px;
    font-weight: 500;
    margin: auto;
    color: #292930;
}

.content h2 {
    font-family: var(--bigfont);
    font-size: 40px;
    font-weight: 600;
}

.cardcontainer {
    background: linear-gradient(111.23deg, #E9F7FF 9.95%, #E0DAFC 85.17%);
    border-radius: 7px;
    padding: 1.563rem;
}

.inner {
    background-color: #FFFFFF;
    padding: 1.25rem 1.5rem;
    border-radius: 7px;
}

.header {
    border-bottom: 1px solid #ECECEC;
    height: 3.8rem;
    margin-bottom: 1rem;
}

.header span {
    border-radius: 59px;
    background: #FFDC60;
    padding: 0.37rem 1.625rem;
    font-family: var(--bigfont);
    font-size: 20px;
    font-weight: 600;
}

.contents h2 {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
}

.contents p {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    color: #555555;
}

.bodies {
    display: flex;
}

.bodies span {
    margin: 0.2rem 0.5rem;
}


.details {
    display: flex;
    gap: 1.87rem;
    justify-content: center;
    align-items: center;
    margin: 3.12rem;
    flex-wrap: wrap;
}

/* -----------------------------Contact from CSS ------------------- */

.form {
    padding: 6.25rem;
    margin: auto;
    display: grid;
    background: linear-gradient(111.23deg, #E9F7FF 9.95%, #E0DAFC 85.17%);
    max-width: 75rem;
    gap: 1.25rem;

}

.first, .second {
    display: flex;
    gap: 3.12rem;
}

.block {
    display: grid;
    gap: 0.81rem;
    flex-basis: 50%;
}

.block label {
    font-family: var(--bigfont);
    font-size: 20px;
    font-weight: 600;
    color: #292930;
}

.block input, .block input::placeholder {
    background-color: #FFFFFF;
    color: #555555;
    font-family: var(--bigfont);
    font-size: 18px;
    font-weight: 500;
}

.block textarea {
    border: none;
    outline: none;
    background-color: #FFFFFF;
    height: 18.75rem;
    color: #555555;
    font-family: var(--bigfont);
    font-size: 18px;
    font-weight: 500;
    resize: none;
    padding: 2rem;
    margin-bottom: 2rem;
}

.btn2 {
    font-family: var(--bigfont);
    font-size: 18px;
    font-weight: 500;
    margin: auto;
    padding: 1rem 2.5rem;
}


@media screen and (max-width: 1024px) {


    .content span {
        font-size: 12px;
    }
    
    .content h2 {
        text-align: center;
        font-size: 30px;
    }

    .contents h2 {
        font-size: 23px;
    }
    
    .contents p {
        font-size: 15px;
    }

    .header span {
        font-size: 18px;
    }

    .form {
        padding: 3rem;
    }
    .details {

    }

    .first, .second {
        gap: 3rem;
        flex-direction: column;
    }
}