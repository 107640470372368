.container {
    background-color: #3C3C45;
    max-width: 24.93rem;
    padding: 49px 53px;
    border-radius: 7px;
    display: grid;
}

.logo {
    display: flex;
    gap: 0.39rem;
}

.logo span {
    font-family: var(--bigfont);
    font-size: 37px;
    font-weight: 800;
    color: #FFFFFF;
}

.cardContent span {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
    color: #FFFFFF;
}

.cardContent p {
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    color: #D0D0D0;
}

input {
    display: flex;
    border: none;
    padding: 1rem 1.62rem;
    color: #FFFFFF;
    border-radius: 7px;
    background-color: #5B5B61;
    margin-bottom: 1.12rem;
}

input::placeholder {
    color: #FFFFFF;
}

input:focus {
    outline: none;
}

.cardbtn {
    font-family: var(--bigfont);
    font-size: 18px;
    font-weight: 500;
    color: #292930;
    background-color: #FFCD1F;
    padding: 1rem 6.62rem;
    border-radius: 7px;
}

@media screen and (max-width: 1024px) {
    .container{
        max-width: 24rem;
    }
    .logo span {
        font-size: 30px;
    }
    .logo img{
        width: 40px;
        height: 30px;
    }
    .container img:nth-child(2){
        max-width: 169px;
    }
    .cardContent span{
        font-size: 20px;
    }
    .cardContent p{
        font-size: 12px;
    }

}