.accordion-button::after {
    /* border-radius: 50%; */
    background-color: transparent;
    color: #FFFFFF;


}

.accordion-button:not(.collapsed)::after {
    color: #FFFFFF;
    background-color: transparent;
}

.accordion-body {
    padding: 2.56rem 3.06rem;
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    color: #D0D0D0;
}

.accordion {
    --bs-accordion-bg: #383840;
    margin: 1.56rem 0;
}

.accordion-item:first-child .accordion-button {
    /* border-top: none; */
    border-radius: 10px;
    padding: 2.62rem;
}

.accordion-button {
    /* border-top: 0.2px solid #fafafa; */
    border-radius: 10px;
}

.accordion-item h2{
    font-family: var(--bigfont);
    font-size: 30px;
    font-weight: 600;
}

.accordion-item:last-child .accordion-button {

    border-radius: 10px;
    font-family: var(--bigfont);
    font-size: 30px;
    font-weight: 600;
}

@media screen and(max-width:1024) {
    .accordion-item h2{
        font-size: 20px;
    }
    .accordion-item:last-child .accordion-button {
        font-size: 20px;
    }
}