.container {
  margin: 0;
  background-color: #292930;
  color: #ffffff;
  margin: auto;
  padding-top: 20px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 10px;
}

.text_content {
  /* max-width: 45.56rem; */
  margin-left: 10rem;
  max-width: 38.56rem;
}

.text_content h3 {
  font-family: var(--bigfont);
  font-size: 32px;
  font-weight: 600;
  color: #8D8AA0;
  margin-top: 2.56rem;
}

.text_content h1 {
  font-family: var(--bigfont);
  font-size: 48px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 4.5rem;
}


.text_content ul {
  list-style: none;
  padding: 0;
  display: grid;
  gap: 4.12rem;
  margin-bottom: 6.12rem;
}

.text_content ul li {
  display: flex;
  align-items: center;
}

.text_content ul li img {
  width: 49px;
  margin-right: 10px;
}

.text_content ul span {
  font-family: var(--bigfont);
  font-size: 32px;
  font-weight: 800;
  color: #ffffff;

}

.know_more_button {
  background-color: #ffffff;
  color: #543EE8;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 40px;
  font-family: var(--bigfont);
  font-size: 24px;
  font-weight: 800;
}

.know_more_button1{
  display: none;
}

@media screen and (max-width: 1024px) {

  .know_more_button1{
    display: flex;
    background-color: #ffffff;
    color: #543EE8;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 40px;
    font-family: var(--bigfont);
    font-size: 24px;
    font-weight: 800;
    align-items: center;
  }
  .know_more_button{
    display: none;
  }
  .content {
    flex-direction: column
  }

  .image_content img {
    width: 100%;
  }

  .text_content {
    margin: auto;
  }

  .text_content h3 {
    font-size: 15px;
  }

  .text_content h1 {
    font-size: 30px;
  }

  .text_content ul span {
    font-size: 20px;
  }

  .text_content ul li img {
    width: 22.26px;
  }

  .text_content ul {
    gap: 10px;
    margin-bottom: 3.31rem;
  }
}