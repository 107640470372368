.about_section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: #f9f9f9;
}



.about_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 40.81rem;
}

.about_content img {
    width: 88px;
    height: 88px;
}

.about_image img {
    width: 100%;
}

.heading {
    padding-bottom: 1.87rem;
}

.inner {
    display: flex;
    gap: 2.43rem;
    border-bottom: 1px solid #CFCFCF;
}

.about_content h2 {
    font-family: var(--bigfont);
    font-size: 2.5rem;
    font-weight: 600;
}

.about_content p {
    color: #555555;
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    margin-top: 1.87rem;
}

.about_content p.head {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
}

.about_content img.icon {
    width: 86px;
    height: 86px;
}

.innerline p {
    padding: 1.37rem 1.2rem;
    border-top: 1px solid #CFCFCF;
    color: #555555;
    font-family: var(--smallfont);
    font-size: 18px;
    font-weight: 400;
    margin-top: 0;
}

.about_sectiontwo {
    width: 570px;
    height: 294px;
    gap: 50px;
}

.sectiontwo {
    display: flex;
}

.innerline span {
    font-family: var(--bigfont);
    font-size: 25px;
    font-weight: 600;
    color: #292930;
    margin: auto 1rem;
}

@media screen and (max-width: 1024px) {

    .about_section {
        flex-direction: column;
    }

    .about_section {
        padding: 0;
    }
    
    .heading{
        text-align: center;
    }
    .heading span {
        display: none;
    }

    .about_content h2 {
        text-align: center;
        font-size: 30px;

    }
    .about_content p {
        font-size: 15px;
    }
    .temp{
        text-align: center;
        margin-bottom: 2rem;
    }
    .inner{
        border:none;
    }
    .about_content img {
        width: 60px;
        height: 60px;
    }
    .icon{
        margin-left: 1rem;
    }
    .innerline span{
        font-size: 20px;
    }
    .innerline p{
        padding: 0.5rem 0.5rem 2rem 1rem;
        font-size: 15px;
    }
}