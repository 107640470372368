.container {
  display: flex;
  justify-content: center;
  border-top: 1px solid #5D5D63;
  border-bottom: 1px solid #5D5D63;
  gap: 7.625rem;
  color: #FFFFFF;
  margin: auto;
}

.block {
  display: flex;
  margin: 3.31rem 0;
  align-items: center;
  max-width: 14.18rem;
  gap: 1.31rem;
}

.number {
  font-family: var(--bigfont);
  font-size: 40px;
  font-weight: 600;
}

.content {
  font-family: var(--smallfont);
  font-size: 15px;
  font-weight: 500;
  color: #D0D0D0;
}

@media screen and (max-width: 1024px) {

  .container {
    flex-direction: column;
    gap: 2.43rem;
    margin: 5.81rem 0;
    border: none;
  }

  .block {
    display: flex;
    margin: auto;
  }
}